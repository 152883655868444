$(function () {
    //Check required inputs
    $('input:not([type="radio"],[type="checkbox"],[data-validate])[required]').on('keyup', function () {
        if ($(this).val() == '' || $(this).val() == null) {
            $(this).closest('.input-group').addClass('error')
        } else {
            $(this).closest('.input-group').removeClass('error')
        }
    });

    //Check required selects
    $('select[required]').change(function () {
        if ($(this).val() == '' || $(this).val() == null) {
            $(this).closest('.input-group').addClass('error');
        } else {
            $(this).closest('.input-group').removeClass('error');
        }
    });

    //Check file on emptiness
    $('input[type="file"][required]').change(function () {
        if ($(this).val() == '') {
            $(this).closest('.input-group').addClass('error');
        } else {
            $(this).closest('.input-group').removeClass('error');
        }
    });

    /*IIN validation*/
    $("[data-validate=\"iin\"][required]").on('keyup', function () {
        if ($(this).val().length==0) {
            $(this).closest('.input-group').addClass('error');
            $(this).closest('.input-group').find('.required').show();
            $(this).closest('.input-group').find('.length').hide();
        } else if ($(this).val().length==12) {
            $(this).closest('.input-group').removeClass('error');
            $(this).closest('.input-group').find('.required').show();
            $(this).closest('.input-group').find('.length').hide();
        } else {
            $(this).closest('.input-group').addClass('error');
            $(this).closest('.input-group').find('.required').hide();
            $(this).closest('.input-group').find('.length').show();
        }
    });
    /**/

    /*Email validation*/
    $("[data-validate=\"email\"][required]").on('keyup', function () {
        if ($(this).val().length==0) {
            $(this).closest('.input-group').addClass('error');
            $(this).closest('.input-group').find('.required').show();
            $(this).closest('.input-group').find('.correct').hide();
        } else if (!validateEmail($(this).val())) {
            $(this).closest('.input-group').addClass('error');
            $(this).closest('.input-group').find('.required').hide();
            $(this).closest('.input-group').find('.correct').show();
        } else {
            $(this).closest('.input-group').removeClass('error');
            $(this).closest('.input-group').find('.required').show();
            $(this).closest('.input-group').find('.length').hide();
        }
    });
    /**/

    /*Phone validation*/
    $("[data-validate=\"phone\"][required]").on('keyup', function () {
        if ($(this).val().length==0) {
            $(this).closest('.input-group').addClass('error');
            $(this).closest('.input-group').find('.required').show();
            $(this).closest('.input-group').find('.correct').hide();
        } else if ($(this).val().indexOf('_') > -1) {
            $(this).closest('.input-group').addClass('error');
            $(this).closest('.input-group').find('.required').hide();
            $(this).closest('.input-group').find('.correct').show();
        } else {
            $(this).closest('.input-group').removeClass('error');
            $(this).closest('.input-group').find('.required').show();
            $(this).closest('.input-group').find('.length').hide();
        }
    });
    /**/

    /*Date validation*/
    $("[data-validate=\"date\"][required]").on('keyup', function () {
        var min = $(this).attr('min'),
            max = $(this).attr('max'),
            value = $(this).val(),
            requiredMessage= $(this).siblings('.alert').find('.required'),
            correctMessage = $(this).siblings('.alert').find('.correct'),
            minMessage = $(this).siblings('.alert').find('.min'),
            maxMessage = $(this).siblings('.alert').find('.max');
        if (value == "") {
            requiredMessage.show().siblings().hide().closest('.input-group').addClass('error');
        } else if (value < min) {
            minMessage.show().siblings().hide().closest('.input-group').addClass('error');
        } else if (value > max) {
            maxMessage.show().siblings().hide().closest('.input-group').addClass('error');
        } else {
            $(this).closest('.input-group').removeClass('.error');
        }
    });
    /**/

    /*Password validation*/
    $("[data-validate=\"password\"][required], [data-validate=\"passwordConfirm\"][required]").on('keyup', function () {
        var a = $(this).closest('form').find('[data-validate="password"]'),
            b = $(this).closest('form').find('[data-validate="passwordConfirm"]');
        if (a.val() == '') {
            a.closest('.input-group').addClass('error');
            b.closest('.input-group').removeClass('error');
        } else {
            a.closest('.input-group').removeClass('error');
            if (a.val() != b.val()) {
                b.closest('.input-group').addClass('error');
            } else {
                b.closest('.input-group').removeClass('error');
            }
        }
    });

    $("[data-validate=\"password\"]:not([required]), [data-validate=\"passwordConfirm\"]:not([required])").on("keyup", function () {
        var a = $(this).closest('form').find('[data-validate="password"]'),
            b = $(this).closest('form').find('[data-validate="passwordConfirm"]');
        if (a.val() != '' || b.val() != '') {
            if (a.val() != b.val()) {
                b.closest('.input-group').addClass('error');
            } else {
                b.closest('.input-group').removeClass('error');
            }
        } else {
            b.closest('.input-group').removeClass('error');
        }
    });
    /**/

    /*Submit button click event*/
    $('form.form-validate button[type="submit"]').click(function () {
        var form = $(this).closest('form');
        validate(form);
        if ($('.error', form).length == 0) {
            return true;
        } else {
            if (form.closest('.fancybox-content').length > 0) {
                $('.fancybox-is-open .fancybox-slide').animate({
                    scrollTop: ($('.error').first().closest('.input-group').offset().top - $('.fancybox-is-open .fancybox-slide .fancybox-content').offset().top)
                },500);
            } else {
                $('body, html').animate({
                    scrollTop: $('.error').first().closest('.input-group').offset().top - 40
                }, 500)
            }
            return false;
        }
    });
    /**/
});

function validate(form) {
    var required = $('input:not([type="radio"],[type="checkbox"],[data-validate])[required]', form);
    required.each(function () {
        if ($(this).val() == '' || $(this).val() == null) {
            $(this).closest('.input-group').addClass('error');
        }
        else {
            $(this).closest('.input-group').removeClass('error');
        }
    });

    //Check required selects
    $('select[required]', form).each(function () {
        if ($(this).val() == '' || $(this).val() == null) {
            $(this).closest('.input-group').addClass('error');
        } else {
            $(this).closest('.input-group').removeClass('error');
        }
    });

    $('input[type="file"][required]', form).each(function () {
        if ($(this).val() == '') {
            $(this).closest('.input-group').addClass('error');
        } else {
            $(this).closest('.input-group').removeClass('error');
        }
    });

    $("[data-validate=\"iin\"][required]", form).each(function () {
        if ($(this).val().length==0) {
            $(this).closest('.input-group').addClass('error');
            $(this).closest('.input-group').find('.required').show();
            $(this).closest('.input-group').find('.length').hide();
        } else if ($(this).val().length==12) {
            $(this).closest('.input-group').removeClass('error');
            $(this).closest('.input-group').find('.required').show();
            $(this).closest('.input-group').find('.length').hide();
        } else {
            $(this).closest('.input-group').addClass('error');
            $(this).closest('.input-group').find('.required').hide();
            $(this).closest('.input-group').find('.length').show();
        }
    });

    $("[data-validate=\"email\"][required]", form).each(function () {
        if ($(this).val().length==0) {
            $(this).closest('.input-group').addClass('error');
            $(this).closest('.input-group').find('.required').show();
            $(this).closest('.input-group').find('.correct').hide();
        } else if (!validateEmail($(this).val())) {
            $(this).closest('.input-group').addClass('error');
            $(this).closest('.input-group').find('.required').hide();
            $(this).closest('.input-group').find('.correct').show();
        } else {
            $(this).closest('.input-group').removeClass('error');
            $(this).closest('.input-group').find('.required').show();
            $(this).closest('.input-group').find('.length').hide();
        }
    });

    $("[data-validate=\"phone\"][required]", form).each(function () {
        if ($(this).val().length==0) {
            $(this).closest('.input-group').addClass('error');
            $(this).closest('.input-group').find('.required').show();
            $(this).closest('.input-group').find('.correct').hide();
        } else if ($(this).val().indexOf('_') > -1) {
            $(this).closest('.input-group').addClass('error');
            $(this).closest('.input-group').find('.required').hide();
            $(this).closest('.input-group').find('.correct').show();
        } else {
            $(this).closest('.input-group').removeClass('error');
            $(this).closest('.input-group').find('.required').show();
            $(this).closest('.input-group').find('.length').hide();
        }
    });

    $("[data-validate=\"date\"][required]", form).each(function () {
        var min = $(this).attr('min'),
            max = $(this).attr('max'),
            value = $(this).val(),
            requiredMessage= $(this).siblings('.alert').find('.required'),
            correctMessage = $(this).siblings('.alert').find('.correct'),
            minMessage = $(this).siblings('.alert').find('.min'),
            maxMessage = $(this).siblings('.alert').find('.max');
        if (value == "") {
            requiredMessage.show().siblings().hide().closest('.input-group').addClass('error');
        } else if (value < min) {
            minMessage.show().siblings().hide().closest('.input-group').addClass('error');
        } else if (value > max) {
            maxMessage.show().siblings().hide().closest('.input-group').addClass('error');
        } else {
            $(this).closest('.input-group').removeClass('.error');
        }
    });

    /*Password validation*/
    $("[data-validate=\"password\"][required], [data-validate=\"passwordConfirm\"][required]", form).each(function () {
        var a = $(this).closest('form').find('[data-validate="password"]'),
            b = $(this).closest('form').find('[data-validate="passwordConfirm"]');
        if (a.val() == '') {
            a.closest('.input-group').addClass('error');
            b.closest('.input-group').removeClass('error');
        } else {
            a.closest('.input-group').removeClass('error');
            if (a.val() != b.val()) {
                b.closest('.input-group').addClass('error');
            } else {
                b.closest('.input-group').removeClass('error');
            }
        }
    });
    /**/
};

//Email validation
function validateEmail(email) {
    var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}